"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require("./");
var ecma402_abstract_1 = require("@formatjs/ecma402-abstract");
var should_polyfill_1 = require("./should-polyfill");
var to_locale_string_1 = require("./src/to_locale_string");
if (should_polyfill_1.shouldPolyfill()) {
    ecma402_abstract_1.defineProperty(Intl, 'DateTimeFormat', { value: _1.DateTimeFormat });
    ecma402_abstract_1.defineProperty(Date.prototype, 'toLocaleString', {
        value: function toLocaleString(locales, options) {
            return to_locale_string_1.toLocaleString(this, locales, options);
        },
    });
    ecma402_abstract_1.defineProperty(Date.prototype, 'toLocaleDateString', {
        value: function toLocaleDateString(locales, options) {
            return to_locale_string_1.toLocaleDateString(this, locales, options);
        },
    });
    ecma402_abstract_1.defineProperty(Date.prototype, 'toLocaleTimeString', {
        value: function toLocaleTimeString(locales, options) {
            return to_locale_string_1.toLocaleTimeString(this, locales, options);
        },
    });
}
