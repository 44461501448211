"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldPolyfill = void 0;
function shouldPolyfill() {
    return (typeof Intl === 'undefined' ||
        !('PluralRules' in Intl) ||
        new Intl.PluralRules('en', { minimumFractionDigits: 2 }).select(1) ===
            'one');
}
exports.shouldPolyfill = shouldPolyfill;
